/* eslint-disable no-tabs */
/* eslint-disable max-len */
import React, { useEffect } from 'react';

function Privacy() {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <main className="page">
      <div className="page__container">
        <h1 className="doc__title-h1">
          Privacy Policy and Cookies
        </h1>
        <p className="doc__text-p">
          This privacy policy describes
          how Pay Primeway collects
          and uses the personal information you provide on our web site.
        </p>
        <p className="doc__text-p">
          At Pay Primeway we care about the privacy of our visitors
          and we are committed to protecting your private data at all times.
          It also describes
          the choices available to you regarding our use of your personal information
          and how you can access and update this information.
        </p>
        <p className="doc__text-p">
          This policy applies
          where we are acting as a data controller
          with respect to the personal data of our website visitors
          and service users, in other words,
          where we determine the purposes and means of the processing of that personal data.
        </p>
        <p className="doc__text-p">
          We will not collect any of your personal information
          that you do not volunteer to make available or consent to for further action.
          We do not sell, share, or rent this personal information to others in any way
          that we have not mentioned in this statement.
        </p>
        <h1 className="doc__title-h1">
          Personal information we collect
        </h1>
        <p className="doc__text-p">
          Personal information means any information relating to an identified
          or identifiable natural person; an identifiable natural person is one
          who can be identified, directly or indirectly,
          in particular by reference to an identifier such as a name,
          an identification number, location data, an online identifier
          or to one or more factors specific
          to the physical, physiological, genetic, mental, economic, cultural
          or social identity of that natural person.
        </p>
        <h1 className="doc__title-h1">
          Personal information we collect from you when you use our Services
        </h1>
        <p className="doc__text-p">
          You choose to give us certain information when you decide to use our services.
          This includes:<br />
        </p>
        <p className="doc__text-p">
          •Your name and email address when you create an account<br />
          •Your postal address, your phone number
          and credit card information when you place an order<br />
        </p>
        <p className="doc__text-p">
          This information is used only to fulfill your specific request
          or process your order, unless you give us permission to use it in another manner,
          for example to add you to one of our contact lists. Once registered with Pay Primeway,
          you authorize us to use the information
          you provide to customize your interaction with our website.
        </p>
        <h1 className="doc__title-h1">
          Personal information we collect automatically when you use our Services
        </h1>
        <p className="doc__text-p">
          As is true of most web sites, we gather certain information automatically
          and store it in log files. This information includes Internet protocol
          (IP) addresses, browser type, Internet service provider (ISP),
          referring/exit pages, operating system, date/time stamp,
          and clickstream data. We use this information to analyze trends,
          to administer the site, to track users movements around the site
          and to gather demographic information about our user base as a whole.
          We link this automatically-collected data to personally identifiable information,
          for example IP addresses are tied to personally identifiable information
          in the remote situations of fraud investigation.
        </p>
        <h1 className="doc__title-h1">
          Personal information we collect using cookies and similar technologies
        </h1>
        <p className="doc__text-p">
          Pay Primeway and its partners use technologies such as cookies,
          or similar technologies to analyze trends, administer the site,
          track users&apos; movements around the site and to gather demographic information about our user base as a whole, and remember our users&apos; settings (e.g. language preference, location etc), or for authentication. Users can control the use of cookies at the individual browser level. If you reject cookies, you may still use our site, but your ability to use some features or areas of our site may be limited.
        </p>
        <h1 className="doc__title-h1">
          What is a cookie?
        </h1>
        <p className="doc__text-p">
          A cookie is a simple text file that is stored on your computer or mobile device by a websites server. Each cookie is unique to your web browser. It will contain some anonymous information such as a unique identifier and the site name and some digits and numbers.
        </p>
        <p className="doc__text-p">
          Most websites you visit use cookies to improve your user experience by allowing the website to &quotremember&quot you, either for the duration of your visit (using a &quotsession cookie&quot) or for repeat visits (using a &quotpersistent cookie&quot).
        </p>
        <p className="doc__text-p">
          Cookies may be set by the website you are visiting (&quotfirst party cookies&quot) or they may be set by other websites who run content on the page you are viewing (&quotthird party cookies&quot).
        </p>
        <h1 className="doc__title-h1">
          What do cookies do?
        </h1>
        <p className="doc__text-p">
          Cookies have lots of different jobs, like letting you navigate between pages efficiently, storing your preferences, and improving your experience of a website. Cookies make the interaction between you and the website faster and easier. If a website doesn&apos;t use cookies, it will think you are a new visitor every time you move to a new page on the site, for example, even after you “log in,” if you move to another page it won&apos;t recognise you and it won&apos;t be able to keep you logged in.
        </p>
        <h1 className="doc__title-h1">
          How does Pay Primeway use cookies?
        </h1>
        <p className="doc__text-p">
          Pay Primeway and its partners use technologies such as cookies, or similar technologies to analyze trends, administer the site, track users&apos; movements around the site and to gather demographic information about our user base as a whole, and remember our users&apos; settings (e.g. language preference, location etc), or for authentication. Users can control the use of cookies at the individual browser level. If you reject cookies, you may still use our site, but your ability to use some features or areas of our site may be limited.
        </p>
        <h1 className="doc__title-h1">
          How does Pay Primeway use cookies?
        </h1>
        <p className="doc__text-p">
          If you prefer, you may choose to block some or all cookies, or even to delete cookies that have already been set; but you should be aware that you might lose some functions of the website.
        </p>
        <p className="doc__text-p">
          If you want to restrict or block the cookies that are set by our Site, or any other site, you can do so through your browser setting. The ‘Help&apos; function in your browser should explain how. You will find general information about cookies and details on how to delete cookies from your machine.
        </p>
        <h1 className="doc__title-h1">
          How we use your personal information
        </h1>
        <p className="doc__text-p">
          We may also process your information for the following reasons:
        </p>
        <p className="doc__text-p">
          •initiate a payment, send or request money, add value to an account;<br />
          •authenticate your access to an Account; communicate with you about your account;<br />
          •create an account connection between your account and a third-party platform;
        </p>
        <h1 className="doc__title-h1">
          How we share your personal information
        </h1>
        <p className="doc__text-p">
          We may disclose your personal information to other members of our group of companies who may use it to:
        </p>
        <p className="doc__text-p">
          •	provide you access to our services (account registration, customer support)<br />
          •	help detect, investigate, mitigate and prevent potentially fraudulent and illegal acts and data security breaches<br />
          •	provide you personalized advertising
        </p>
        <p className="doc__text-p">
          We may share your information with third parties who provide services on our behalf to help with our business activities. These companies are authorized to use your personal information only as necessary to provide these services to us.
        </p>
        <p className="doc__text-p">
          These services may include:
        </p>
        <p className="doc__text-p">
          •	Fulfilling orders and delivering packages<br />
          •	Payment processing and Chargeback Representment<br />
          •	Providing customer support<br />
          •	Sending marketing communications<br />
          •	Fulfilling subscription services<br />
          •	Designate a cell network
        </p>
        <p className="doc__text-p">
          We may also disclose your personal information:
        </p>
        <p className="doc__text-p">
          •	in response to lawful requests by public authorities, including to meet national security or law enforcement requirements;<br />
          •	as required by law, such as to comply with a subpoena, or similar legal process;<br />
          •	when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud;<br />
          •	if Pay Primeway is involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our Web site of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal information;<br />
          •	to any other third party with your prior consent to do so.
        </p>
        <h1 className="doc__title-h1">
          Your rights regarding your personal information
        </h1>
        <p className="doc__text-p">
          We have summarised the rights that you have under data protection law. Some of the rights are complex, and not all of the details have been included in our summaries. Accordingly, you should read the relevant laws and guidance from the regulatory authorities for a full explanation of these rights:
        </p>
        <p className="doc__text-p">
          •	You have the right to be informed about how your personal information is collected, for what purposes is being collected and how is being used<br />
          •	You have the right to access the personal information we hold about you<br />
          •	You have the right to request the correction of inaccurate personal information we hold about you<br />
          •	You have the right to request that we delete your data, or stop processing it or collecting it, in some circumstances<br />
          •	You have the right to request that we transfer or port elements of your data<br />
          •	You have the right to complain to your data protection regulator
        </p>
        <h1 className="doc__title-h1">
          How long do we keep your personal information
        </h1>
        <p className="doc__text-p">
          We will retain your information for as long as your account is active or as needed to provide you services. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
        </p>
        <p className="doc__text-p">
          Upon request Pay Primeway will provide you with information about whether we hold any of your personal information. You may access, correct, or request deletion of your personal information by making the change on our member information page or by emailing our Customer Support.
        </p>
        <h1 className="doc__title-h1">
          How do we protect your personal information
        </h1>
        <p className="doc__text-p">
          We use reasonable precautions to keep the personal information you disclose both in your browsing and your purchases and to never release this information to third parties. Your personally identifiable information is kept secure. Only authorized employees have access to this information. When you enter sensitive information (such as a credit card number) on our order forms, we encrypt the transmission of that information using secure socket layer technology (SSL). We follow generally accepted standards to protect the personal information submitted to us, both during transmission and once we receive it. No method of transmission over the Internet, or method of electronic storage, is 100% secure, however. Therefore, we cannot guarantee its absolute security.
        </p>
        <p className="doc__text-p">
          We may use some data collected to investigate, prevent or take action regarding illegal activities and violations of our Terms and Conditions.
        </p>
        <h1 className="doc__title-h1">
          Obtaining Your Consent
        </h1>
        <p className="doc__text-p">
          By using our website, you give Pay Primeway permission to collect information about the pages served to you as an anonymous user for the purpose of calculating aggregate site statistics. If you register with Pay Primeway, you authorize Pay Primeway to use the information you provide in order to personalize the information we deliver to you and to use your demographic information when calculating aggregate customer data. Pay Primeway may occasionally amend this policy. We suggest that you bookmark this page and check to see if it has changed from time to time. If we make any material changes we will notify you by email or by means of a notice on this Site prior to the change becoming effective
        </p>
      </div>
    </main>
  );
}
export default Privacy;
