/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState, useRef } from 'react';
import checkmark from '../../images/checkmark.png';
import ProductCard from './ProductCard/ProductCard';
import PopupInfo from '../Popups/PopupInfo/PopupInfo';
import PopupTransfer from '../Popups/PopupTransfer/PopupTransfer';
import { Outlet, useOutletContext, useSearchParams, useParams } from 'react-router-dom';
import { getProviderProducts } from '../../utils/apiProducts';
import PopupPayment from '../Popups/PopupPayment/PopupPayment';
import { postCharge } from '../../utils/apiCharge';
import styles from './Products.module.css';

function Products() {
  const [searchParams] = useSearchParams();
  const [selectedProvider] = useOutletContext();
  const [providerProducts, setProviderProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [infoPopupProps, setInfoPopupProps] = useState({});
  const openTransferNext = useRef(false);
  const openPaymentNext = useRef(false);
  const transferDataRef = useRef({});
  const chargeDataRef = useRef({});
  const titleRef = useRef(null);
  const [isTransferOpen, setIsTransferOpen] = useState(false);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);

  const searchParamStatus = searchParams.get('status');
  const params = useParams();

  useEffect(() => {
    if (searchParamStatus !== null) {
      if (searchParamStatus === 'success') {
        setInfoPopupProps({
          ...infoPopupProps,
          status: 'success',
          infoText: 'The product has been aquired',
          buttonText: 'Close',
          isOpen: true, //это нужно будет удалить
        })
        setIsInfoOpen(true)
      } else {
        setInfoPopupProps({
          ...infoPopupProps,
          status: 'failed',
          infoText: 'The product has not been aquired',
          buttonText: 'Close',
          isOpen: true, //это нужно будет удалить
        })
        setIsInfoOpen(true)
      }
    }
  }, []);

  useEffect(() => {
    getProviderProducts(params.providerCode)
      .then((data) => {
        setProviderProducts(data.info);
      });
  }, [params])

  useEffect(() => {
    titleRef.current.scrollIntoView();
  }, [selectedProvider])

  function closeInfoPopup() {
    setIsInfoOpen(false)
  }

  function handleProductCardClick(product) {
    setIsTransferOpen(true);
    setSelectedProduct(product);
  }

  function closeTransferPopup() {
    setIsTransferOpen(false);
  }

  function updateTransferData(transferData) {
    transferDataRef.current = transferData
  }

  function charge(chargeData) {
    chargeDataRef.current = {
      ...chargeData,
    }

    postCharge(chargeDataRef.current)
      .then((res) => {
          window.location.href = res.redirectURL;
      })
      .catch((err) => {
        console.log('ERROR', err)
        setInfoPopupProps({
          status: 'failed',
          infoText: `${err}`,
          buttonText: 'Close',
        })
        setIsPaymentOpen(false);
        setIsInfoOpen(true);
      })
  }

  function switchIsPaymentOpen(state) {
    setIsPaymentOpen(state);
  }

  function switchIsTransferOpen(state) {
    setIsTransferOpen(state);
  }

  function switchOpenTransferNext(state) {
    openTransferNext.current = state;
  }

  function setInfoProps(data) {
    setInfoPopupProps({
      ...data,
    })
  }

  function openInfo() {
    console.log('openInfo')
    setIsInfoOpen(true)
  }

  return (
    <>
      <div className={styles.products}>
        <div className={styles.title}>
          <h2 ref={titleRef} className={styles.title_text}>Choose a Product<a name="products" /></h2>
          <button className={styles.title_button} type="button">
            <img src={checkmark} alt="click to choose a Product" />
          </button>
        </div>
        <section className={styles.grid}>
          {providerProducts.map((product) => (
            <ProductCard
              key={product.SkuCode}
              product={product}
              provider={selectedProvider}
              onClick={handleProductCardClick}
            />
          ))}
        </section>
        <Outlet />
      </div>

      {isTransferOpen && <PopupTransfer
        provider={selectedProvider}
        product={selectedProduct}
        onClose={closeTransferPopup}
        switchIsPaymentOpen={switchIsPaymentOpen}
        updateTransferData={updateTransferData}
        switchIsTransferOpen={switchIsTransferOpen}
        switchOpenTransferNext={switchOpenTransferNext}
        setInfoProps={setInfoProps}
        openInfo={openInfo}
      />}

      {isPaymentOpen && <PopupPayment
        switchIsPaymentOpen={switchIsPaymentOpen}
        transferData={transferDataRef.current}
        provider={selectedProvider}
        charge={charge}
      />}

      {isInfoOpen && <PopupInfo
        status={infoPopupProps.status}
        closePopup={closeInfoPopup}
        infoText={infoPopupProps.infoText}
        buttonText={infoPopupProps.buttonText}
        buttonClick={closeInfoPopup}
        switchIsPaymentOpen={switchIsPaymentOpen}
        openPaymentNext={openPaymentNext.current}
        switchIsTransferOpen={switchIsTransferOpen}
        openTransferNext={openTransferNext.current}
        openInfo={openInfo}
      />}

    </>
  );
}
export default Products;
