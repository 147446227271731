/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { IMask, IMaskInput } from 'react-imask';
import { cardNumberMask, expirationDateMask, securityCodeMask, nameMask } from './masks';
import { svgIcons } from './svgIcons';
import flutterwaveLogo from '../../../images/flutterwave.svg';
import infokeyLogo from '../../../images/group56logo.svg';

export default function PopupPayment(props) {
  const [name, setName] = useState('');
  const [cvv, setCvv] = useState('');
  const [date, setDate] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [isNameCompleted, setIsNameCompleted] = useState(false);
  const [isCodeCompleted, setIsCodeCompleted] = useState(false);
  const [isDateCompleted, setIsDateCompleted] = useState(false);
  const [isNumberCompleted, setIsNumberCompleted] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const refCardImage = useRef(null);
  const refExpirationDateInput = useRef(null);
  const refSecurityCodeInput = useRef(null);
  const refCardNumberInput = useRef(null);

  const ccicon = document.getElementById('ccicon');
  const ccsingle = document.getElementById('ccsingle');

  useEffect(() => {
    if (isNameCompleted && isCodeCompleted && isDateCompleted && isNumberCompleted) {
      setIsButtonActive(true);
    } else {
      setIsButtonActive(false)
    }
  }, [isNameCompleted, isNumberCompleted, isDateCompleted, isCodeCompleted])

  // On Input Change Events
  useEffect(() => {
    if (name.length == 0) {
      document.getElementById('svgname').innerHTML = 'John Doe';
      document.getElementById('svgnameback').innerHTML = 'John Doe';
    } else {
      document.getElementById('svgname').innerHTML = name;
      document.getElementById('svgnameback').innerHTML = name;
    }
  }, [name]);

  useEffect(() => {
    if (cardNumber.length == 0) {
      document.getElementById('svgnumber').innerHTML = '0123 4567 8910 1112';
    } else {
      document.getElementById('svgnumber').innerHTML = cardNumber;
    }
  }, [cardNumber]);

  useEffect(() => {
    if (date.length == 0) {
      document.getElementById('svgexpire').innerHTML = '01/23';
    } else {
      document.getElementById('svgexpire').innerHTML = date;
    }
  }, [date])

  useEffect(() => {
    if (cvv.length == 0) {
      document.getElementById('svgsecurity').innerHTML = '985';
    } else {
      document.getElementById('svgsecurity').innerHTML = cvv;
    }
  }, [cvv]);

  // CREDIT CARD IMAGE JS
  // document.querySelector('.preload').classList.remove('preload');

  const flippCard = () => {
    if (refCardImage.current.classList.contains('flipped')) {
      refCardImage.current.classList.remove('flipped');
    } else {
      refCardImage.current.classList.add('flipped');
    }
  }

  //On Focus Events
  const removeFlipped = () => {
    refCardImage.current.classList.remove('flipped');
  }

  const addFlipped = () => {
    refCardImage.current.classList.add('flipped');
  }

  // define the color swap function
  const swapColor = function (basecolor) {
    document.querySelectorAll('.lightcolor')
      .forEach(function (input) {
        input.setAttribute('class', '');
        input.setAttribute('class', 'lightcolor ' + basecolor);
      });
    document.querySelectorAll('.darkcolor')
      .forEach(function (input) {
        input.setAttribute('class', '');
        input.setAttribute('class', 'darkcolor ' + basecolor + 'dark');
      });
  };

  // pop in the appropriate card icon when detected
  const cardnumberOnAccept = function (mask) {
    switch (mask.masked.currentMask.cardtype) {
      case 'american express':
        ccicon.innerHTML = svgIcons.amex;
        ccsingle.innerHTML = svgIcons.amex_single;
        swapColor('green');
        break;
      case 'visa':
        ccicon.innerHTML = svgIcons.visa;
        ccsingle.innerHTML = svgIcons.visa_single;
        swapColor('lime');
        break;
      case 'diners':
        ccicon.innerHTML = svgIcons.diners;
        ccsingle.innerHTML = svgIcons.diners_single;
        swapColor('orange');
        break;
      case 'discover':
        ccicon.innerHTML = svgIcons.discover;
        ccsingle.innerHTML = svgIcons.discover_single;
        swapColor('purple');
        break;
      case ('jcb' || 'jcb15'):
        ccicon.innerHTML = svgIcons.jcb;
        ccsingle.innerHTML = svgIcons.jcb_single;
        swapColor('red');
        break;
      case 'maestro':
        ccicon.innerHTML = svgIcons.maestro;
        ccsingle.innerHTML = svgIcons.maestro_single;
        swapColor('yellow');
        break;
      case 'mastercard':
        ccicon.innerHTML = svgIcons.mastercard;
        ccsingle.innerHTML = svgIcons.mastercard_single;
        swapColor('lightblue');
        break;
      case 'unionpay':
        ccicon.innerHTML = svgIcons.unionpay;
        ccsingle.innerHTML = svgIcons.unionpay_single;
        swapColor('cyan');

        break;
      default:
        ccicon.innerHTML = '';
        ccsingle.innerHTML = '';
        swapColor('grey');
        break;
    }
  };

  const onSubmit = function () {
    const cardNum = cardNumber.replace(/\D/g, "")
    const expiryMonth = date.slice(0, 2)
    const expiryYear = date.slice(3,)

    const cardData = {
      name,
      cardNum,
      expiryMonth,
      expiryYear,
      cvv,
    }

    const reqData = {
      transferData: props.transferData,
      cardData: cardData,
    }

    props.charge(reqData)
  }

  return (
    // eslint-disable-next-line react/prop-types
    <div className="payment-popup__underlayer payment-popup_open"
    >
      <div className="payment-section">
        <div className="payment-header">
          <div className="payment-info-block">
            <img src={flutterwaveLogo} className="payment-info-block__logo" />
            <p className="payment-info-block__text">{props.provider.Name}</p>
            <h3 className="payment-info-block__text_last">
              {`${props.transferData.sendValue} ${props.transferData.sendCurrencyIso}`}
            </h3>
          </div>
          <img src={infokeyLogo} className="payment-header__logo" />
        </div>

        <div className="container preload" id="preload">
          <div className="creditcard"
            ref={refCardImage}
            onClick={flippCard}
          >
            <div className="front">
              <div id="ccsingle"></div>
              <svg version="1.1" id="cardfront" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px" y="0px" viewBox="0 0 750 471" style={{ enableBackground: `new 0 0 750 471` }} xmlSpace="preserve">
                <g id="Front">
                  <g id="CardBackground">
                    <g id="Page-1_1_">
                      <g id="amex_1_">
                        <path id="Rectangle-1_1_" className="lightcolor grey" d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                        C0,17.9,17.9,0,40,0z" />
                      </g>
                    </g>
                    <path className="darkcolor greydark"
                      d="M750,431V193.2c-217.6-57.5-556.4-13.5-750,24.9V431c0,22.1,17.9,40,40,40h670C732.1,471,750,453.1,750,431z" />
                  </g>
                  <text transform="matrix(1 0 0 1 60.106 295.0121)" id="svgnumber" className="st2 st3 st4">0123 4567 8910
                    1112</text>
                  <text transform="matrix(1 0 0 1 54.1064 428.1723)" id="svgname" className="st2 st5 st6">JOHN DOE</text>
                  <text transform="matrix(1 0 0 1 54.1074 389.8793)" className="st7 st5 st8">cardholder name</text>
                  <text transform="matrix(1 0 0 1 479.7754 388.8793)" className="st7 st5 st8">expiration</text>
                  <text transform="matrix(1 0 0 1 65.1054 241.5)" className="st7 st5 st8">card number</text>
                  <g>
                    <text transform="matrix(1 0 0 1 574.4219 433.8095)" id="svgexpire" className="st2 st5 st9">01/23</text>
                    <text transform="matrix(1 0 0 1 479.3848 417.0097)" className="st2 st10 st11">VALID</text>
                    <text transform="matrix(1 0 0 1 479.3848 435.6762)" className="st2 st10 st11">THRU</text>
                    <polygon className="st2" points="554.5,421 540.4,414.2 540.4,427.9 		" />
                  </g>
                  <g id="cchip">
                    <g>
                      <path className="st2" d="M168.1,143.6H82.9c-10.2,0-18.5-8.3-18.5-18.5V74.9c0-10.2,8.3-18.5,18.5-18.5h85.3
                    c10.2,0,18.5,8.3,18.5,18.5v50.2C186.6,135.3,178.3,143.6,168.1,143.6z" />
                    </g>
                    <g>
                      <g>
                        <rect x="82" y="70" className="st12" width="1.5" height="60" />
                      </g>
                      <g>
                        <rect x="167.4" y="70" className="st12" width="1.5" height="60" />
                      </g>
                      <g>
                        <path className="st12" d="M125.5,130.8c-10.2,0-18.5-8.3-18.5-18.5c0-4.6,1.7-8.9,4.7-12.3c-3-3.4-4.7-7.7-4.7-12.3
                        c0-10.2,8.3-18.5,18.5-18.5s18.5,8.3,18.5,18.5c0,4.6-1.7,8.9-4.7,12.3c3,3.4,4.7,7.7,4.7,12.3
                        C143.9,122.5,135.7,130.8,125.5,130.8z M125.5,70.8c-9.3,0-16.9,7.6-16.9,16.9c0,4.4,1.7,8.6,4.8,11.8l0.5,0.5l-0.5,0.5
                        c-3.1,3.2-4.8,7.4-4.8,11.8c0,9.3,7.6,16.9,16.9,16.9s16.9-7.6,16.9-16.9c0-4.4-1.7-8.6-4.8-11.8l-0.5-0.5l0.5-0.5
                        c3.1-3.2,4.8-7.4,4.8-11.8C142.4,78.4,134.8,70.8,125.5,70.8z" />
                      </g>
                      <g>
                        <rect x="82.8" y="82.1" className="st12" width="25.8" height="1.5" />
                      </g>
                      <g>
                        <rect x="82.8" y="117.9" className="st12" width="26.1" height="1.5" />
                      </g>
                      <g>
                        <rect x="142.4" y="82.1" className="st12" width="25.8" height="1.5" />
                      </g>
                      <g>
                        <rect x="142" y="117.9" className="st12" width="26.2" height="1.5" />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Back">
                </g>
              </svg>
            </div>
            <div className="back">
              <svg version="1.1" id="cardback" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px" y="0px" viewBox="0 0 750 471" style={{ enableBackground: `new 0 0 750 471` }} xmlSpace="preserve">
                <g id="Front">
                  <line className="st0" x1="35.3" y1="10.4" x2="36.7" y2="11" />
                </g>
                <g id="Back">
                  <g id="Page-1_2_">
                    <g id="amex_2_">
                      <path id="Rectangle-1_2_" className="darkcolor greydark" d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                    C0,17.9,17.9,0,40,0z" />
                    </g>
                  </g>
                  <rect y="61.6" className="st2" width="750" height="78" />
                  <g>
                    <path className="st3" d="M701.1,249.1H48.9c-3.3,0-6-2.7-6-6v-52.5c0-3.3,2.7-6,6-6h652.1c3.3,0,6,2.7,6,6v52.5
                C707.1,246.4,704.4,249.1,701.1,249.1z" />
                    <rect x="42.9" y="198.6" className="st4" width="664.1" height="10.5" />
                    <rect x="42.9" y="224.5" className="st4" width="664.1" height="10.5" />
                    <path className="st5"
                      d="M701.1,184.6H618h-8h-10v64.5h10h8h83.1c3.3,0,6-2.7,6-6v-52.5C707.1,187.3,704.4,184.6,701.1,184.6z" />
                  </g>
                  <text transform="matrix(1 0 0 1 621.999 227.2734)" id="svgsecurity" className="st6 st7">985</text>
                  <g className="st8">
                    <text transform="matrix(1 0 0 1 518.083 280.0879)" className="st9 st6 st10">security code</text>
                  </g>
                  <rect x="58.1" y="378.6" className="st11" width="375.5" height="13.5" />
                  <rect x="58.1" y="405.6" className="st11" width="421.7" height="13.5" />
                  <text transform="matrix(1 0 0 1 59.5073 228.6099)" id="svgnameback" className="st12 st13">John Doe</text>
                </g>
              </svg>
            </div>
          </div>
        </div>

        <div className="form-container">
          <div className="field-container">
            <label htmlFor="name">Name</label>
            <IMaskInput
              className="input_radius"
              id="name"
              maxLength="23"
              type="text"
              mask={nameMask}
              value={name}
              placeholder="Name"

              onFocus={() => removeFlipped()}
              onAccept={(text) => setName(text.toUpperCase())}

              onComplete={(value) => {
                /[a-zA-Z]{2} [a-zA-Z]{2}/.test(value) ? setIsNameCompleted(true) : setIsNameCompleted(false)
              }}
            />
          </div>

          <div className="field-container">
            <label htmlFor="cardnumber">Card Number</label>
            <IMaskInput
              id="cardnumber"
              // unmask={true}
              type="text"
              pattern="[0-9]*"
              inputMode="numeric"
              className="input_radius"
              ref={refCardNumberInput}
              // inputRef={(el) => this.input = el}
              mask={cardNumberMask}
              unmask={true}
              placeholder="Card number"
              dispatch={(appended, dynamicMasked) => {
                var cardNum = (dynamicMasked.value + appended).replace(/\D/g, "");
                return dynamicMasked.compiledMasks.find(function (m) {
                  const regex = m.regex && new RegExp(m.regex);
                  return !regex || cardNum.match(regex);
                });
              }}

              onFocus={() => removeFlipped()}

              onAccept={(number, mask) => {
                setIsNumberCompleted(false)
                setCardNumber(number);
                cardnumberOnAccept(mask);
              }}

              onComplete={() => setIsNumberCompleted(true)}
            />
            <svg id="ccicon" className="ccicon" width="750" height="471" viewBox="0 0 750 471" version="1.1"
              xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">

            </svg>
          </div>
          <div
            className="field-container"
          >
            <label htmlFor="expirationdate">Expiration (mm/yy)</label>
            <IMaskInput
              className="input_radius"
              id="expirationdate"
              ref={refExpirationDateInput}
              mask={expirationDateMask}
              blocks={{
                MM: {
                  mask: IMask.MaskedRange,
                  from: 1,
                  to: 12
                },
                YY: {
                  mask: IMask.MaskedRange,
                  from: 22,
                  to: 99
                },
              }}
              // unmask={true}
              type="text"
              pattern="[0-9]*"
              inputMode="numeric"
              placeholder="Expiration (MM/YY)"

              onFocus={() => {
                removeFlipped()
              }}

              onAccept={(val) => {
                setIsDateCompleted(false)
                setDate(val)
              }}

              onComplete={() => setIsDateCompleted(true)}
            />
          </div>

          <div className="field-container">
            <label htmlFor="securitycode">Security Code</label>
            <IMaskInput
              className="input_radius"
              id="securitycode"
              ref={refSecurityCodeInput}
              mask={securityCodeMask}
              unmask={true}
              type="text"
              pattern="[0-9]*"
              inputMode="numeric"
              aria-labelledby="CVC/CVV-код"
              placeholder="CVC/CVV-code"

              onFocus={() => addFlipped()}

              onAccept={(v) => {
                setCvv(v)
                setIsCodeCompleted(false)
              }}

              onComplete={() => setIsCodeCompleted(true)}

            />
          </div>
        </div>
        <div className="button-container">
          <div className="button-container__element">
            <button
              type="reset"
              className="button-body button-body_grey"
              onClick={() => props.switchIsPaymentOpen(false)}
            >
              Cancel
            </button>
          </div>

          <div className="button-container__element">
            <button
              type="submit"
              className={`button-body ${isButtonActive ? "button-body_green" : "button-body_grey"}`}
              onClick={() => onSubmit()}
            >
              Continue
            </button>
          </div>

        </div>
      </div>
    </div>
  )
}
