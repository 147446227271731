import React, { useEffect } from 'react';

function Terms() {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <main className="page">
      <div className="page__container">
        <h1 className="doc__title-h1">
          Terms and Conditions
        </h1>
        <p className="doc__text-p">
          1. General
        </p>
        <p className="doc__text-p">
          The provision of Services to you through
          the Website is subject to these Terms & Conditions,
          the Privacy Notice, the Cookies Policy and, if applicable,
          your acceptance of the Contract.
          In the event of any conflict or ambiguity between any of these documents,
          the terms of the Contract will prevail.
        </p>
        <p className="doc__text-p">
          By using the Website, you are bound by these Terms & Conditions.
          Please read these Terms & Conditions carefully before accessing the Services.
          You should print a copy of these Terms & Conditions for future reference.
        </p>
        <p className="doc__text-p">
          If you object to these Terms & Conditions or any subsequent modifications to it,
          or become dissatisfied with your Contract in any way, your only remedy is to immediately:
          a) discontinue your use of the Website; and/or b) terminate your Contract.
          No other remedy, legal or otherwise, is available to you save for a) and
          b) mentioned above.
        </p>
        <p className="doc__text-p">
          Certain words in these Terms & Conditions have the meanings set out at Section 26.
        </p>
        <p className="doc__text-p">
          2. Information about Us
        </p>
        <p className="doc__text-p">
          The Website is owned by, and the Services are provided by,
          Pay Primeway Ltd (“Pay Primeway”).
          Pay Primeway is incorporated in Nigeria has its registered office at 9,
          Eko Street, Parkview, Ikoyi, Lagos.<br />
          You can contact also Pay Primeway using the contact information on the website.
        </p>
        <p className="doc__text-p">
          3. Username and Password
        </p>
        <p className="doc__text-p">
          To access the Website and use the Services, you must have a username and password.
          When registering you may supply personal information,
          including your email address (“Login Details”).
        </p>
        <p className="doc__text-p">
          You agree that the information supplied on registration will be truthful,
          accurate and complete.
          It is your responsibility to inform us of any changes to that information.
          All personal information you provide to us will be treated in accordance
          with our Privacy Notice.
        </p>
        <p className="doc__text-p">
          You are entirely responsible for all activities which occur when using your Login Details,
          including payment for any Services purchased.
          You must notify us immediately if you become
          aware of any unauthorised use of your Login Details.
          If you forget or lose your Login Details,
          you should request a new password by visiting the Forgot your password.
          Login Details may only be used by a single user and are not transferable.
        </p>
        <p className="doc__text-p">
          4. Your data
        </p>
        <p className="doc__text-p">
          Pay Primeway will comply with all of its obligations under applicable data protection
          law with regard to relevant data in its possession relating to you.
          Data collected by us as part of the Services
          will be treated in accordance with our Privacy Notice and Cookie Policy.
          These documents set out how we use and protect the information you provide to us.
          We recommend that you read the Privacy Notice and Cookies Policy carefully.
        </p>
        <p className="doc__text-p">
          Please note that the sending of data via e-mail over the internet may not be secure
          and can be intercepted by third parties or incorrectly delivered.
          You should not divulge personal information over the internet
          unless you are using a secure or encrypted communications technology.
        </p>
        <p className="doc__text-p">
          We shall be entitled, but not obliged,
          to record all communications from, or instructions given by,
          you to us, or messages sent by us to you through the Website.
        </p>
        <p className="doc__text-p">
          5. Your Instructions
        </p>
        <p className="doc__text-p">
          You shall ensure that all instructions
          given by you to us through the Website are accurate and complete,
          and that, where appropriate, you correctly identify the mobile phone number,
          account number or access account to which any Top-Up is to be credited.
          In particular, prior to confirming any instruction to us,
          you shall ensure that the instruction
          which is relayed back to you confirming the instruction that you send through the Website
          is the instruction which you intend to give.
          We are entitled to rely on any instruction from you using and,
          for the avoidance of doubt,
          the processing by us of any such confirmed instruction shall be final and binding on you.
        </p>
        <p className="doc__text-p">
          You shall carefully examine any Top-Up information received by you,
          or any other information provided by us to you through the Website from time to time
          and shall report any errors or omissions
          to us in writing within 30 (thirty) days from the date of Pay Primeways invoice.
          In the event that no error or omission is reported by you within the time specified,
          we shall be entitled to rely on
          the conclusiveness of the relevant invoice and settlement report.
        </p>
        <p className="doc__text-p">
          6. Your Obligations
        </p>
        <p className="doc__text-p">
          The equipment necessary for you to access the Website
          shall be provided and be maintained by and at your expense.
          Pay Primeway reserves the right to modify the equipment and software requirements
          as is necessary for it to continue
          or improve the provision of Services through the Website.
        </p>
        <p className="doc__text-p">
          You undertake to us to comply strictly with this Agreement.
          You acknowledge that your compliance with this Agreement
          is designed to minimize the risk of unauthorised use of the Website.
        </p>
        <p className="doc__text-p">
          7. Transactions on the Account
        </p>
        <p className="doc__text-p">
          To send Top-Up,
          you agree to comply with and undertake the provisions set out in this Section 8.
          You authorise us to act upon any instruction to debit an Account
          received through the Website
          which has been transmitted using the username and password
          and/or any other authentication process
          which we may require to be used in connection with the Website
          without requiring us to make any further authentication
          or enquiry,
          and all such debits shall constitute your liability.
        </p>
        <p className="doc__text-p">
          The Top-Up service shall only be provided to you by Pay Primeway in respect of the mobile phone
          operators available on the Website
          which are subject to change and availability.
          In the case of mobile phone Top-Up,
          you will be required to input the mobile phone number or account number
          to which any Top-Up is to be credited into the appropriate space on the Website.
          It is your responsibility to ensure that you have correctly inputted the number.
          You will then be required to select the amount of Top-Up that you wish to credit
          that mobile phone number or account number with.
        </p>
        <p className="doc__text-p">
          In the case of Top-Up,
          you will be required to insert the email address, associated to a permanent access account
          to which any Top-Up is to be credited,
          into the appropriate space on the Website.
          It is your responsibility to ensure that you have correctly entered the email address.
          You will then be required to select the amount of Top-Up
          that you wish to credit that access account with.
        </p>
        <p className="doc__text-p">
          The Top-Up is sent instantly by Pay Primeway to the appropriate number
          or access account upon successful payment.
          Occasionally,
          there may be a short delay before the relevant mobile operator applies the Top-Up.
          We will send you a confirmation prompt
          which contains details of the Top-Up as soon as your Top-Up transaction
          has been successfully sent.
          You are required to issue the send customer
          with an appropriate receipt in accordance with the Contract and applicable local laws.
        </p>
        <p className="doc__text-p">
          You agree and understand that Pay Primeway only acts on your authorisation
          to send Top-Up and the relevant mobile operator
          shall be solely liable to the recipient of Top-Up
          for the provision of services related to the Top-Up.
          Once the Top-Up is sent to a mobile phone number,
          account number or access account,
          it can be used immediately therefore it cannot be refunded or removed.
          To stop this mistake from happening,
          Pay Primeway asks you to confirm through the order summary that the mobile phone number,
          account number or email address you have entered is correct.
        </p>
        <p className="doc__text-p">
          The Website can only be used by you
          to effect a limited number of Top-Ups and/or Top-Ups
          to a limited value over specific time periods.
          You will be automatically notified through the Website when these limits are reached
          or should attempt to effect Top-Ups in excess of the limits.\
          Other limits and exclusions may apply to the way
          which you can use this Website from time to time
          and you will be notified of these through your use of the Website.
        </p>
        <p className="doc__text-p">
          For the avoidance of doubt, in accordance with your Contract,
          you shall be solely responsible for any fraudulent activities and the resulting effects
          which occur on your system,
          including any losses to the Account balance.
        </p>
        <p className="doc__text-p">
          8. Auto Fund
        </p>
        <p className="doc__text-p">
          If you select “Auto Fund”,
          the payment method provided
          by you will be charged for (i) the initial Top-Up
          and (ii) the Auto Fund top-up amount specified
          by you each time your Balance falls below the specified amount.
          You will be charged for an Auto Fund
          top-up no more than 24 hours before the Auto Fund top-up is sent.
          If the payment method provided by you is declined for payment of your Auto Fund top-up,
          your Auto Fund top-up will be cancelled,
          and you will be required to create a new Auto Fund with a valid payment method.
        </p>
        <p className="doc__text-p">
          You may cancel any Auto Fund you have set up on your Account
          at any time and the cancellation
          will be effective before the next Auto Fund top-up payment is due to be paid.
          You will not receive a refund for any previous Auto Fund top-up payments.
        </p>
        <p className="doc__text-p">
          Auto Fund top-ups are automatically applied unless discontinued.
          You can manage or cancel your Auto Fund in the Auto Fund settings in your Account
          or by contacting our partner support team.
        </p>
        <p className="doc__text-p">
          Pay Primeway reserves the right to accept, refuse, discontinue or cancel the Auto Fund Service.
        </p>
        <p className="doc__text-p">
          9. Termination of the Agreement
        </p>
        <p className="doc__text-p">
          We may terminate the Agreement and withdraw the Website
          and the Services provided through it:
        </p>
        <p className="doc__text-p">
          (a) in accordance with the terms of your Contract;
        </p>
        <p className="doc__text-p">
          (b) immediately upon breach by you
          of any of the terms of this Agreement and provided you are informed by notice in writing
          as soon as is reasonably possible after termination;
        </p>
        <p className="doc__text-p">
          (c) immediately upon your inability to pay amounts due or other contractual incapacity;
        </p>
        <p className="doc__text-p">
          (d) if you have not accessed the Website in any period of twelve (12) consecutive months;
          or
        </p>
        <p className="doc__text-p">
          (e) if we reasonably believe that the Website
          or any of the Services have been used negligently,
          illegally or fraudulently by you,
          or by a third party as a result of your negligence or recklessness.
        </p>
        <p className="doc__text-p">
          We reserve the right to process or cancel any transactions in progress
          on termination of these Terms & Conditions or on suspension
          or withdrawal of the Services.
          We are not responsible for any loss you
          may incur as a result of any transaction not being processed
          as part of the Services after termination of these Terms & Conditions
          or after any suspension or withdrawal of the Services.
        </p>
        <p className="doc__text-p">
          10. Your Contribution
        </p>
        <p className="doc__text-p">
          When you send us any feedback, suggestions, ideas or other materials in relation to or via
          our Website or the Services,
          you agree that we can use,
          reproduce, publish, modify, adapt and transmit them to others free of charge
          and without restriction, subject to our obligations in the Privacy Notice
          and Cookies Policy.
        </p>
        <p className="doc__text-p">
          11. VAT and Tax
        </p>
        <p className="doc__text-p">
          The total amount (inclusive of all applicable taxes and charges)
          that is required to be paid will be displayed clearly on the Website
          before you are asked to confirm the transaction
          and proceeding with the transaction at this point is entirely optional.
          No fees, surcharges or any additional amounts
          may be added to this total amount unless expressly agreed to by Pay Primeway.
        </p>
        <p className="doc__text-p">
          12. Access to the Website
        </p>
        <p className="doc__text-p">
          Access to and use of this Website is at the users own risk
          and we do not represent or warrant that the use of this Website
          or any material downloaded from it will not cause damage to property,
          including but not limited to loss of data or computer virus infection.
          In no event do we accept liability
          of any description including liability for negligence
          for any damages whatsoever resulting from loss of use,
          data or profits arising out of
          or in connection with the access,
          use or performance of this Website or any its contents, or the Services.
        </p>
        <p className="doc__text-p">
          When using the Services you and we agree that we each
          will not attempt to attempt to repudiate the validity
          of your instructions relating to the Services
          or the communications regarding the instructions sent to you by us.
          Both you and we agree that the instructions, information,
          communications and/or authorisations given through the Services
          shall be treated as satisfying any legal requirements for communication in writing.
        </p>
        <p className="doc__text-p">
          13. Variations of the Agreement
        </p>
        <p className="doc__text-p">
          We may alter these Terms & Conditions from time to time.
          Any such alteration shall become effective
          and shall be binding upon you 14 days after notice of such alteration has been sent to you
          by any of the following means: through the post,
          email or by posting a message on the Website.
          You shall be entitled,
          upon receiving notice of any alteration to these Terms & Conditions,
          to immediately terminate these Terms & Conditions but without prejudice to any rights
          or obligations which have arisen prior the termination date,
          including your liability for any indebtedness on any Account
          or which has otherwise arisen prior to that termination date.
        </p>
        <p className="doc__text-p">
          14. Notices
        </p>
        <p className="doc__text-p">
          Save where expressly provided, any notice required to be given by us to you in connection
          with the subject matter of these Terms & Conditions may be given
          by any of the following means:by email
          or through the Website.
        </p>
        <p className="doc__text-p">
          15. Confidentiality
        </p>
        <p className="doc__text-p">
          You shall ensure that all information obtained from us by you relating to our operations,
          services,
          pricing, software, hardware and/or systems in connection with these Terms & Conditions
          shall be treated by you in strictest confidence
          and shall not be disclosed by you to any third party
          or used by you for any purpose,
          other than for the purposes of fulfilling your obligations under these Terms & Conditions.
        </p>
        <p className="doc__text-p">
          16. Temporary Withdrawal of Service
        </p>
        <p className="doc__text-p">
          In the event of disruption to, or a failure, unavailability, fault or malfunction of,
          or connected to, any product or system used in connection with the Website
          or the Services,
          or where there is a real or potential security risk,
          Pay Primeway shall be entitled, without incurring any liability to you,
          to temporarily suspend the relevant Services
          or access to the Website for such reasonable period as may be required to remedy, address
          or resolve the issue. Pay Primeway may also suspend the Website
          and/or Services as required for maintenance
          (whether emergency or planned) or upgrade work.
          You further agree and acknowledge that your access to the Website and/or Account
          and/or Services may be suspended where Pay Primeway reasonably believes
          that it has been used improperly,
          unlawfully or fraudulently.
        </p>
        <p className="doc__text-p">
          17. Force Majeure
        </p>
        <p className="doc__text-p">
          Pay Primeway shall not be liable
          or in breach of its obligations under these Terms & Conditions
          if there is any total
          or partial failure of performance of our duties
          and obligations occasioned by any act of God, fire,
          act of government or state, war, civil commotion, insurrection, sanction,
          embargo, inability to communicate with third parties for whatever reason,
          failure of any computer dealing or settlement system, failure of
          or delay in any mobile phone network, prevention from or hindrance in obtaining any energy
          or other supplies (including airtime),
          labour disputes of whatever nature, late or mistaken payment by an agent
          or any other reason
          (whether or not similar in kind to any of the above) beyond our control.
        </p>
        <p className="doc__text-p">
          18. Security, Maintenance and Availability
        </p>
        <p className="doc__text-p">
          You agree, acknowledge and accept that electronic communications,
          the internet, telephone lines or SMS-based telecommunications media may not be secure
          and communications via such media may be intercepted by unauthorised persons
          or delivered incorrectly.
          In consequence we cannot guarantee the privacy
          or confidentiality of communications via such media although Pay Primeway
          will put in place appropriate security measures to protect these methods
          of communications.
        </p>
        <p className="doc__text-p">
          From time to time it may be necessary to
          or desirable for security reasons, maintenance, upgrades or other reasons to:
        </p>
        <p className="doc__text-p">
          (a) make the Website or certain or all of the Services unavailable to you; and/or
        </p>
        <p className="doc__text-p">
          (b) delay implementation of any new Services; and/or
        </p>
        <p className="doc__text-p">
          (c) withdraw, replace or reissue passwords; and/or
        </p>
        <p className="doc__text-p">
          (d) change authentication procedures or processes for accessing the Website
          or the Services and while we will use reasonable endeavours
          to minimise any inconvenience caused to you,
          you accept that these events may occur and that
          we have no liability to you in the event of this happening.
          Where we change authentication procedures for accessing the Website
          or the Services then, notwithstanding any other term of these Terms & Conditions,
          we may introduce these procedures
          by giving instructions to you via the Website in respect of which
          such procedures are being introduced.
        </p>
        <p className="doc__text-p">
          19. Limited Liability
        </p>
        <p className="doc__text-p">
          Pay Primeway bears no responsibility for the improper, immoral, unauthorised,
          fraudulent or illegal use of the Website.
          Without prejudice to your rights under the Contract, Pay Primeway,
          its directors, employees, officers
          or agents exclude all liability and responsibility for any amount
          or kind of loss or damage that may result to you or a third party
          (including without limitation any indirect, incidental, special,
          or consequential, exemplary
          or punitive loss or damage or any loss of income, money, data or goodwill)
          arising out of or in connection with your use of the Website or the Services.
          This does not limit in any way our liability for death
          or personal injury caused by our negligence
          r for any other matter which it would be illegal for us to exclude our liability.
        </p>
        <p className="doc__text-p_special">
          Pay Primeways total liability in contract,
          tort (including negligence or breach of statutory duty),
          or misrepresentation, restitution, or otherwise,
          arising out of or in connection with these Terms & Conditions
          shall be limited to the appropriate
          then-current Account balance.
        </p>
        <p className="doc__text-p">
          You agree and acknowledge that the Website
          and the Services and content provided through it are provided “as is”.
          To the fullest extent permitted by law,
          Pay Primeway makes no warranties in relation to the use and availability of the Website
          or the Services provided through it.
        </p>
        <p className="doc__text-p">
          20. Severability
        </p>
        <p className="doc__text-p">
          If, at any time, any provision
          of these Terms & Conditions (or any part of a provision of these Terms & Conditions)
          is or becomes illegal, invalid or unenforceable,
          that shall not affect or impair the legality,
          validity or enforceability the remainder of these Terms & Conditions
          (including the remainder of a provision where only part thereof is or has become illegal,
          invalid or unenforceable).
        </p>
        <p className="doc__text-p">
          21. Waiver
        </p>
        <p className="doc__text-p">
          Any waiver by us of a breach or default of any of the provisions
          of these Terms & Conditions shall not be construed as a waiver
          of any succeeding breach of the same or other provisions,
          nor shall any delay or omission on our part to exercise
          or avail of any right, power or privilege
          that we have or may have, operate as a waiver of any breach or default by you.
        </p>
        <p className="doc__text-p">
          22. Language
        </p>
        <p className="doc__text-p">
          These Terms & Conditions and all other documentation
          which will be provided by us to communicate
          with you throughout the duration of the agreement between
          you and us will be prepared in the English language,
          and all communications between us and you will also be conducted in the English language.
        </p>
      </div>
    </main>
  );
}

export default Terms;
