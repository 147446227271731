import React, { useEffect, useState } from 'react';
import { postSupportRequest } from '../../../utils/apiEmail';
import PopupInfo from '../../Popups/PopupInfo/PopupInfo';

function Support() {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [infoPopupProps, setInfoPopupProps] = useState({});

  useEffect(() => {
    window.scroll(0, 0);
  }, [])

  function resetForm() {
    setName('');
    setPhone('');
    setEmail('');
    setMessage('');
  }

  function handleSubmit(e) {
    e.preventDefault();
    const data = {
      name,
      phone,
      email,
      message,
    }

    postSupportRequest(data)
      .then((res) => {
        if (res.ok) {
          setInfoPopupProps({
            ...setInfoPopupProps,
            infoText: 'You message has been received.\n',
            status: 'success',
            buttonText: 'Close',
            isOpen: true,
          })
          resetForm();
        } else {
          return Promise.reject();
        }
      })
      .catch(() => {
        setInfoPopupProps({
          ...setInfoPopupProps,
          infoText: `Internal server error. Try to send your message later.`,
          status: 'failed',
          buttonText: 'Close',
          isOpen: true,
        })
      })
  }

  function closeInfoPopup() {
    setInfoPopupProps({
      ...infoPopupProps,
      isOpen: false,
    })
  }

  return (
    <main className="page">
      <div className="page__container">

        <form
          className="form__support"
          onSubmit={handleSubmit}
        >

          <input type="hidden" name="project_name" value="payprimeway.com" />
          <input type="hidden" name="admin_email" value="info@payprimeway.com" />
          <input type="hidden" name="form_subject" value="Custormer request from the website" />

          <div>
            <input
              id="name"
              required
              autoComplete="off"
              type="text"
              name="Name"
              data-error="Error"
              placeholder="Name"
              className="input content__name_input"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="container__info">

            <div className="container__phone">
              <input
                required
                id="phone"
                autoComplete="off"
                type="tel"
                name="Phone"
                data-error="Error"
                placeholder="Phone"
                className="content__phone_input"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="container__email">
              <input
                required
                id="email"
                autoComplete="off"
                type="email"
                name="E-mail"
                data-error="Error"
                placeholder="Email"
                className="content__email_input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <div>
            <textarea
              required
              id="text"
              autoComplete="off"
              name="Message text"
              data-error="Error"
              placeholder="Type your message"
              className="content__textarea"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>

          <div className="content__buttons">
            <div className="content__buttons_body buttons__body">
              <button type="submit" className="button buttons__body_btn-continue support__button">
                SUBMIT
              </button>
            </div>
          </div>
        </form>
      </div>

      <PopupInfo
        isOpen={infoPopupProps.isOpen}
        status={infoPopupProps.status}
        closePopup={closeInfoPopup}
        infoText={infoPopupProps.infoText}
        buttonText={infoPopupProps.buttonText}
        buttonClick={closeInfoPopup}
        openTransferNext={false}
        openPaymentNext={false}
      />

    </main>
  );
}

export default Support;
