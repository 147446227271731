/* eslint-disable react/prop-types */
import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../images/group56logo.svg';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__body">
          <div className="footer__main main">
            <div className="main__block block">
              <div className="block__logo">
                <img className="block__logo-image" src={logo} alt="logo" onClick={() => window.scroll(0, 0)} />
              </div>
              <div className="block__text">
                <p className="block__text-p">
                  A market place offering a wide variety of products from all kinds
                  of merchants and service providers.
                </p>
              </div>
            </div>

            <nav className="nav__body">
              <ul className="nav__list">
                <li className="nav__li">
                  <ul className="nav__list_items">
                    <li className="nav__list_item">
                      <NavLink className="nav__list_link" to={`/`}>Home</NavLink>
                    </li>
                  </ul>
                </li>
                <li className="nav__li">
                  <ul className="nav__list_items">
                    <li className="nav__list_item">
                      <NavLink className="nav__list_link" to="/support">Support</NavLink>
                    </li>
                  </ul>
                </li>
                <li className="nav__li">
                  <ul className="nav__list_items">
                    <li className="nav__list_item">
                      <NavLink className="nav__list_link" to="/about-us">About us</NavLink>
                    </li>
                  </ul>
                </li>
                <li className="nav__li">
                  <ul className="nav__list_items">
                    <li className="nav__list_item">
                      <NavLink className="nav__list_link" to="/terms-and-conditions">Terms and conditions</NavLink>
                    </li>
                  </ul>
                </li>
                <li className="nav__li">
                  <ul className="nav__list_items">
                    <li className="nav__list_item">
                      <NavLink className="nav__list_link" to="/privacy-and-policy">Privacy Policy</NavLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>

          <div className="info">
            <a href="tel:+2348074141378" className="info__tel">+2348074141378</a>
            <a href="mailto:support@payprimeway.com" className="info__tel">support@payprimeway.com</a>
            <div className="info__adress">
              Registered office: 9, Eko Street, Parkview, Ikoyi, Lagos, Nigeria.
            </div>
            <div className="info__copyrihgt">©2022 PAY PRIMEWAY LTD</div>
          </div>

        </div>
      </div>
    </footer>
  );
}

export default Footer;
