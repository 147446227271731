const apiUrls = {
  protocolDev: 'http',
  hostnameDev: 'localhost',
  backPortDev: 4000,
  redirectUrlDev: 'http://localhost:4000/api/processing/charge/res',
  providers: '/api/providers',
  countries: '/api/countries',
  products: '/api/products',
  transfer: '/api/transfer',
  charge: '/api/processing/charge',
  updateTransfer: '/api/transfer/update',
  payment: '/api/payment',
  support: '/api/support',
  verifyPayment: '/api/payment/verify',
  error: '/api/errors',
  flwPayments: 'https://api.flutterwave.com/v3/payments',
  // !!! настроить
  backPortProd: '',
  logoUrlDev: 'https://payprimeway.com/logo192.png',
  redirectUrlProd: 'https://payprimeway.com/api/processing/charge/res',
  protocolProd: 'https',
  hostnameProd: 'payprimeway.com',
};

export default apiUrls;
